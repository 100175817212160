<template>
  <div class="containter-404">
    <div class="content-404">
      <img src="../../assets/image/common/404d.png" alt="" />
      <p>抱歉页面不存在</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.containter-404 {
  .content-404 {
    height: 260px;
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -130px 0 0 -300px;
    img {
      width: 80%;
      height: 80%;
    }
    p {
      margin-top: 20px;
    }
  }
}
</style>
