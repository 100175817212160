var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "containter-404" }, [
      _c("div", { staticClass: "content-404" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/image/common/404d.png"),
            alt: "",
          },
        }),
        _c("p", [_vm._v("抱歉页面不存在")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }